.alert-notification {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 9999;
}

.alert-dismissible .close {
  padding: 0.6rem 1.25rem 0.75rem !important;
}

.App-nav {
  background: black;
  min-height: 30px;
  padding: 6px;
}

.App-nav .nav-link {
  color: white;
}

.App-nav .nav-link.active {
  background-color: #214957;
}

.nav-pills .show > .nav-link {
  background-color: #214957;
}

.App-logo {
  height: 100%;
  width: auto;
}

.App-header {
  height: 90px;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 5px;
}

input,
.form-control[readonly] {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
  -webkit-box-shadow 0.15s ease-in-out;
}

input[type='checkbox'],
input[type='radio'] {
  width: auto;
  height: auto;
  display: initial;
}

.form-control {
  border: 1px solid hsl(0, 0%, 80%);
}

.form-control[readonly] {
  background-color: #fff !important;
}

input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.cursor-pointer {
  cursor: pointer;
}

.simulate-a {
  color: #214957;
  cursor: pointer;
}

.simulate-a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.alert-notification {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 9999;
}

.alert-dismissible .close {
  padding: 0.6rem 1.25rem 0.75rem !important;
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background: none !important;
  padding-right: .75rem !important;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-select-eli * {
  height: calc(2.25rem + 2px);
}

.custom-container {
  padding-top: 20px;
}

.nav-item {
  margin-left: 10px;
}

.table td,
.table th {
  vertical-align: middle;
}

.btn-today {
  background-color: white;
  padding: 0px 1px;
  border-radius: 2px;
  position: absolute;
  top: -12px;
  right: 15px;
  z-index: 1;
  cursor: pointer;
}

.simulate-a {
  color: #007bff;
  cursor: pointer;
}

.simulate-a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.spinner-container {
  position: fixed;
  bottom: 5%;
  left: 50%;
  background-color: rgba(0, 176, 194, .2);
  padding: 10px;
  border-radius: 50%;
  z-index: 1100;
  background-color: white;
}

.fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.fileContainer [type=file] {
  cursor: inherit;
  display: block;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.my-pagination {
  display: flex;
  align-items: baseline;
}

.my-pagination nav {
  margin-left: auto;
}
.custom-checkbox .custom-control-label {
  cursor: pointer;
}

.css-1pcexqc-container {
  z-index: 2;
}

.react-datepicker-popper {
  z-index: 3;
}

.select-container > div {
  z-index: auto;
}

.select-container input {
  box-shadow: none;
}
