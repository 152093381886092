.page-404-before {
  background: white;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
}

.page-404 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(10, 102, 183, .2);
  animation: backgroundColor 15s infinite;
}

.page-404 .center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.page-404 .title, .page-404 .sub-title {
  color: black;
  position: relative;
  top: -50px;
}

.page-404 .title {
  font-size: 72px;
  animation: moveTitle 30s infinite;
}

.page-404 .sub-title {
  font-size: 36px;
  animation: moveSubTitle 30s infinite;
}

.page-404 .logo-404 {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes backgroundColor {
  0% {
    background-color: rgba(128, 128, 128, .2);
  }
  20% {
    background-color: rgba(154, 205, 50, .2);
  }
  40% {
    background-color: rgba(0, 128, 0, .2);
  }
  60% {
    background-color: rgba(47, 82, 146, .2);
  }
  80% {
    background-color: rgba(138, 43, 226, .2);
  }
  100% {
    background-color: rgba(255, 69, 0, .1);
  }
}

@keyframes moveTitle {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(-5px);
  }
}

@keyframes moveSubTitle {
  0% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
}
